import _objectSpread from "C:/webPoject/mango_manager_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from "@/assets/styles/variables.scss";
export default {
  name: "Sidebar",
  components: {
    Logo: Logo,
    SidebarItem: SidebarItem
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["sidebar", "rule"])), {}, {
    routes: function routes() {
      var _this = this;
      var routes = this.$router.options.routes;
      var resRoutes = [];
      routes.forEach(function (item) {
        // 动态渲染菜单,权限101为全部开启
        if (item.meta.roles.includes(_this.rule)) {
          resRoutes.push(item);
        }
      });
      return resRoutes;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    },
    variables: function variables() {
      return _variables;
    }
  })
};
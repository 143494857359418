import "C:\\webPoject\\mango_manager_admin\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "C:\\webPoject\\mango_manager_admin\\node_modules\\core-js\\modules\\es.promise.js";
import "C:\\webPoject\\mango_manager_admin\\node_modules\\core-js\\modules\\es.object.assign.js";
import "C:\\webPoject\\mango_manager_admin\\node_modules\\core-js\\modules\\es.promise.finally.js";
import Vue from 'vue';
import App from './App';
import store from './store';
import router from './router';
import axios from "axios";
import './plugins/axios';
import './plugins/element';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/index.scss';
import '@/icons'; // icon
import '@/permission'; // permission control

//时间操作
import { parseTime } from "@/utils/operate-time";

//列表优化
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import VueVirtualScroller from 'vue-virtual-scroller';
import commonApi from '@/utils/commonApi.js';
Vue.prototype.commonApi = commonApi;
import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel);

// import *as echarts from 'echarts'
// Vue.prototype.$echarts = echarts

Vue.config.productionTip = false;
Vue.prototype.$store = store;
Vue.prototype.parseTime = parseTime;
Vue.prototype.axios = axios;
var isDebug = true; // 控制是否屏蔽全局console.log 日志；isDebug设为false即可屏蔽
// console.log = (function (oldLogFunc) {
//   return function () {
//     if (isDebug) {
//       oldLogFunc.apply(this, arguments);
//     }
//   }
// })(console.log);

Vue.use(VueVirtualScroller);
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');
//
//
//
//
//
//

import Router from 'vue-router';
export default {
  name: 'App'
};
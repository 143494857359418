//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import defaultSettings from "@/settings";
var title = defaultSettings.title;
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    appName: function appName() {
      return this.$store.state.user.appName;
    }
  },
  data: function data() {
    return {
      title: title || "Vue Admin Template",
      logo: true
    };
  }
};
import _objectSpread from "C:/webPoject/mango_manager_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.trim.js";
import { login, getInfo, logout } from '@/api/admin';
import { api_app_list } from '@/api/app';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
var state = {
  aid: '',
  token: '',
  rule: 0,
  name: '',
  avatar: '',
  channelId: 0,
  pid: 0,
  appName: '',
  userRoleType: '',
  //登录用户为投手才有
  distributionId: ''
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_RULE: function SET_RULE(state, rule) {
    state.rule = rule;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_CHANNEL_ID: function SET_CHANNEL_ID(state, channelId) {
    state.channelId = channelId;
  },
  SET_PID: function SET_PID(state, pid) {
    state.pid = pid;
  },
  SET_APPNAME: function SET_APPNAME(state, appName) {
    state.appName = appName;
  },
  SET_ROLETYPE: function SET_ROLETYPE(state, userRoleType) {
    state.userRoleType = userRoleType;
  },
  SET_DISID: function SET_DISID(state, distributionId) {
    state.distributionId = distributionId;
  },
  SET_AID: function SET_AID(state, aid) {
    state.aid = aid;
  }
};
var actions = {
  // 用户登录
  UserLogin: function UserLogin(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password,
      loginType = userInfo.loginType;
    return new Promise(function (resolve, reject) {
      login({
        username: username.trim(),
        password: password,
        loginType: loginType
      }).then(function (response) {
        var resData = response;
        if (resData.code == 200) {
          commit('SET_AID', resData.data.aid);
          commit('SET_RULE', resData.data.role_id);
          commit('SET_NAME', resData.data.nickname);
          commit('SET_AVATAR', resData.data.avatarUrl);
          commit('SET_TOKEN', resData.data.token);
          commit('SET_CHANNEL_ID', resData.data.channelId);
          commit('SET_PID', resData.data.pid);
          commit('SET_APPNAME', resData.data.appName);
          commit('SET_ROLETYPE', resData.data.userRoleType);
          if (resData.data.userRoleType == 'pitcher') {
            commit('SET_DISID', resData.data.distributionId);
          }
          window.sessionStorage.setItem('token', resData.data.token);
          window.sessionStorage.setItem('pid', resData.data.pid);
          window.sessionStorage.setItem('appName', resData.data.appName);
          window.sessionStorage.setItem('userRoleType', resData.data.userRoleType);
          window.sessionStorage.setItem('aid', resData.data.aid);
          setToken(resData.data.token); //存在cookie中
        }

        resolve(resData);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 获取小程序列表
  GetAppList: function GetAppList(_ref2, params) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      api_app_list(_objectSpread({}, params)).then(function (response) {
        var resData = response;
        resolve(resData);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 获取用户信息
  // 把获取到的token进行鉴别
  UserGetInfo: function UserGetInfo(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      getInfo({
        token: state.token
      }).then(function (response) {
        var data = response.data;
        if (!data) {
          reject('Verification failed, please Login again.');
        }
        var name = data.name,
          avatar = data.avatar;
        commit('SET_NAME', name);
        commit('SET_AVATAR', avatar);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 退出登录
  UserLogout: function UserLogout(_ref4) {
    var commit = _ref4.commit,
      state = _ref4.state;
    commit('SET_RULE', 0);
    commit('SET_NAME', '');
    commit('SET_AVATAR', '');
    commit('SET_TOKEN', '');
    commit('SET_CHANNEL_ID', 0);
    commit('SET_PID', 0);
    commit('SET_APPNAME', '');
    sessionStorage.clear();
    removeToken();
    resetRouter();
  },
  // 清空token
  UserResetToken: function UserResetToken(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
import http from "@/plugins/axios";
// 用户列表
export function api_user_list(data) {
  return http({
    url: "/user/list",
    method: 'get',
    data: data
  });
}
// Ta的追剧
export function api_user_getWatch(data) {
  return http({
    url: "/user/getWatch",
    method: 'get',
    data: data
  });
}

// 跳转其他的管理后台
export function api_user_getRemoteParam(data) {
  return http({
    url: "/remoteAdmin/getRemoteParam",
    method: 'get',
    data: data
  });
}
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

// https://webpack.js.org/guides/dependency-management/#requirecontext
var modulesFiles = require.context('./modules', true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  // set './app.js' => 'app'
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new Vuex.Store({
  modules: modules,
  getters: getters,
  // 数据持久化
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    // 或者使用window.localStorage本地存储（该项目不需要）
    reducer: function reducer(val) {
      return {
        app: val.app,
        user: val.user,
        settings: val.settings
      };
    }
  })]
});
export default store;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "div",
            { staticClass: "other-box" },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.checkExpress()
                    }
                  }
                },
                [_vm._v("跳转新后台系统")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "other-box" },
            [
              _c("span", { staticClass: "tabapp-text" }, [
                _vm._v("切换小程序")
              ]),
              _c(
                "el-select",
                {
                  attrs: {
                    size: "small",
                    filterable: "",
                    placeholder: "小程序"
                  },
                  on: { change: _vm.selectPid },
                  model: {
                    value: _vm.pid,
                    callback: function($$v) {
                      _vm.pid = $$v
                    },
                    expression: "pid"
                  }
                },
                _vm._l(_vm.appList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.appName, value: item.pid }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("span", { staticClass: "user-name" }, [
                  _vm._v("主后台：" + _vm._s(_vm.name))
                ]),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [_vm._v("退出登录")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
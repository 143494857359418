import http from "@/plugins/axios";
// 小程序列表
export function api_app_list(data) {
  return http({
    url: "/app/list",
    method: 'get',
    data: data
  });
}
// 添加或修改小程序
export function api_app_addOrUpdate(data) {
  return http({
    url: "/app/addOrUpdate",
    method: 'post',
    data: data
  });
}
// 删除小程序
export function api_app_delete(data) {
  return http({
    url: "/app/delete",
    method: 'delete',
    data: data
  });
}
export function api_app_distributor_list(data) {
  return http({
    url: "/app/getAppDistributor",
    method: 'get',
    data: data
  });
}
export function api_app_list_by_type(data) {
  return http({
    url: "/app/getListByType",
    method: 'get',
    data: data
  });
}
export function api_app_config(data) {
  return http({
    url: "/app/config",
    method: 'get',
    data: data
  });
}
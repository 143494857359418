import http from "@/plugins/axios";
// 登录
export function login(data) {
  return http({
    url: "/admin/login",
    method: 'post',
    data: data
  });
}
// 渠道商账号列表
export function api_admin_list(data) {
  return http({
    url: "/admin/list",
    method: 'get',
    data: data
  });
}
// 添加或修改渠道商账号
export function api_admin_addOrUpdate(data) {
  return http({
    url: "/admin/addOrUpdate",
    method: 'post',
    data: data
  });
}
// 删除渠道商账号
export function api_admin_delete(data) {
  return http({
    url: "/admin/delete",
    method: 'delete',
    data: data
  });
}

//分销商列表
export function api_distributor_list(data) {
  return http({
    url: "/distributor/list",
    method: 'get',
    data: data
  });
}

// 小程序所属剧目权限（老分销商接口）
export function api_distribution_list(data) {
  return http({
    url: "/distribution/list",
    method: 'get',
    data: data
  });
}
// 添加或修改分销商账号
export function api_distribution_addOrUpdate(data) {
  return http({
    url: "/distribution/addOrUpdate",
    method: 'post',
    data: data
  });
}
// 删除分销商账号
export function api_distribution_delete(data) {
  return http({
    url: "/distribution",
    method: 'delete',
    data: data
  });
}
// 短剧列表
export function api_distribution_videoList(data) {
  return http({
    url: "/distribution/videoList",
    method: 'get',
    data: data
  });
}
export function api_post_distributor(data) {
  return http({
    url: "/distributor/postDistributor",
    method: 'post',
    data: data
  });
}
import router from "./router";
import store from "@/store";
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getToken } from '@/utils/auth'; // get token from cookie
import getPageTitle from '@/utils/get-page-title';
NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

var whiteList = ['/login']; // no redirect whitelist

// 路由导航守卫
router.beforeEach(function (to, from, next) {
  document.title = getPageTitle(to.meta.title);
  if (to.path === '/login') {
    next();
    NProgress.done();
  } else {
    var token = sessionStorage.getItem('token') || store.state.user.token;
    if (!token) {
      next('/login');
      NProgress.done();
    } else {
      if (to.path == '/') {
        next({
          path: '/home'
        });
        return;
      }
      next();
    }
  }
});
router.afterEach(function () {
  // finish progress bar
  NProgress.done();
});
"use strict";

import _objectSpread from "C:/webPoject/mango_manager_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import axios from "axios";
import { getToken } from "@/utils/auth";
import qs from 'qs-stringify';
import { message } from "@/plugins/message";
import store from "@/store";
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
// 不同环境不同地址
var apis = {
  production: process.env.VUE_APP_apiUrl,
  // production: 'https://192.168.0.138:8082/api', // 线上 (生成环境) :  https://www.yanxingwl.cn/api
  //development: 'https://192.168.0.138:8082/api', // 本地代理请求 (开发环境) :  http://localhost:8081/api
  //production: 'https://test-192.168.0.138:8082/api', // 线上 (生成环境) :  https://www.yanxingwl.cn/api
  //development: 'https://test-192.168.0.138:8082/api', // 本地代理请求 (开发环境) :  http://localhost:8081/api
  development: process.env.VUE_APP_apiUrl
  // development: 'http://localhost:8082/api',
  // development: 'http://test-manager-api.jrweid.com/api/', // 本地代理请求 (开发环境) :  http://localhost:8081/api
  //development: 'http://ldworktest.natapp1.cc/api', // 本地代理请求 (开发环境) :  http://localhost:8081/api

  // http://test-manager-api.jrweid.com/api/
  // http://test-manager-api.jrweid.com/
};

var config = {
  baseURL: process.env.NODE_ENV === 'production' ? apis.production : apis.development,
  timeout: 60 * 1000,
  withCredentials: true
};
var extra = {};
// create an axios instance
var service = axios.create(config);
// request interceptor
service.interceptors.request.use(function (config) {
  var token = window.sessionStorage.getItem('token') || store.state.user.token;
  if (token) {
    config.headers.token = token;
  }
  if (config.method === "post") {
    config.data = _objectSpread(_objectSpread({}, extra), config.data);
    switch (config.url) {
      case '/login':
        config.data = qs(config.data);
        break;
      case '/quartz/job/pause':
        config.data = qs(config.data);
        break;
      case '/quartz/job/resume':
        config.data = qs(config.data);
        break;
      case '/quartz/job/update':
        config.data = qs(config.data);
        break;
      case '/quartz/job/delete':
        config.data = qs(config.data);
        break;
      default:
        config.data = JSON.parse(JSON.stringify(config.data));
    }
  } else {
    config.params = _objectSpread(_objectSpread({}, extra), config.params);
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  // console.log('请求接口结果-----------', response.data)
  // if(response.data.code != 200) message.error(response.data.message)
  if (response.data.code == null) {
    var msg = response.data.message;
    // ToDo
    if (msg == '' || msg == undefined) {} else if (msg.indexOf('请重新登录') > -1) {
      window.location.hash = '/login';
    }
    // if (msg == 'token过期,请重新登录!') {
    // 	window.location.hash = '/login'
    // }
  }

  return response.data;
}, function (error) {
  console.log('error-->', error);
  message.error(error.info || '服务开了小差，请稍后重试');
  return Promise.reject(error);
});

// 请求封装
var request = function request(val) {
  var data = val.data,
    url = val.url,
    method = val.method;
  // data.APPID = 'wxf14a7fc9f84e245b';
  var pid = window.sessionStorage.getItem('pid') || store.state.user.pid;
  if (data) {
    if (!data.notPid) {
      // 带全局pid进行传参
      if (!data.pid) {
        data.pid = Number(pid);
      }
    } else {
      // 不带全局pid，只传自定义数据
      data = data._data_;
    }
  } else {
    data = {
      pid: Number(pid)
    };
  }
  // console.log('请求接口参数-----------', data);
  if (method === 'get' || method === 'GET') {
    // get

    return service({
      url: url,
      method: method,
      params: data
    });
  } else if (method === 'post' || method === 'POST') {
    // post

    return service({
      url: url,
      method: method,
      data: data
    });
  } else {
    // delete 

    url = url + '/' + data.id;
    data = '';
    return service({
      url: url,
      method: method,
      data: data
    });
  }
};
export default request;
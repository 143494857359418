// 时间戳转成时间 D:H:M
function getDate(number, format) {
  var date = new Date(number);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? '0' + m : m;
  var d = date.getDate();
  d = d < 10 ? '0' + d : d;
  var h = date.getHours();
  h = h < 10 ? '0' + h : h;
  var minute = date.getMinutes();
  var second = date.getSeconds();
  minute = minute < 10 ? '0' + minute : minute;
  second = second < 10 ? '0' + second : second;
  // return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;//年月日时分秒
  if (format == 'hms') {
    return h + ':' + minute + ':' + second;
  } else {
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute;
  }
}

// 选择筛选日期
var pickerOptions = {
  shortcuts: [{
    text: '昨天',
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
      picker.$emit('pick', [start, start]);
    }
  }, {
    text: '近7天',
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '近30天',
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '近60天',
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '近90天',
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '近120天',
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 120);
      picker.$emit('pick', [start, end]);
    }
  }]
};
export default {
  getDate: getDate,
  pickerOptions: pickerOptions
};
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

// 101: 管理员
// 102：普通用户
// 103：渠道用户
// 104: 分销用户
var isAllRs = [101, 102, 103, 104];
var isAmdinRs = [101];
var isUserRs = [101, 102];
export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  meta: {
    title: '',
    icon: 'form',
    roles: isAllRs
  },
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  meta: {
    title: '',
    icon: 'form',
    roles: isAllRs
  },
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/home',
  component: Layout,
  meta: {
    title: '',
    icon: 'form',
    roles: isUserRs
  },
  redirect: '/home/index',
  children: [{
    path: 'index',
    name: 'home-index',
    component: function component() {
      return import('@/views/home/index');
    },
    meta: {
      title: '首页',
      icon: 'form',
      affix: true,
      roles: isUserRs
    }
  }]
}, {
  path: '/statistic_analysis',
  component: Layout,
  meta: {
    title: '数据分析',
    icon: 'form',
    roles: isAmdinRs
  },
  redirect: '/statistic_analysis',
  children: [{
    path: 'pitcher',
    name: 'statistic_analysis_pitcher',
    component: function component() {
      return import('@/views/statistic_analysis/pitcher');
    },
    meta: {
      title: '投手充值统计',
      icon: 'form',
      roles: isAmdinRs,
      keepAlive: true
    }
  }, {
    path: 'video',
    name: 'pitcher',
    component: function component() {
      return import('@/views/statistic_analysis/video');
    },
    meta: {
      title: '短剧充值统计',
      icon: 'form',
      roles: isAmdinRs,
      keepAlive: true
    }
  }, {
    path: 'app',
    name: 'app',
    component: function component() {
      return import('@/views/statistic_analysis/app');
    },
    meta: {
      title: '小程序充值统计',
      icon: 'form',
      roles: isAmdinRs,
      keepAlive: true
    }
  }, {
    path: 'video_order',
    name: 'video_order',
    component: function component() {
      return import('@/views/statistic_analysis/video_order.vue');
    },
    meta: {
      title: '短剧订单充值统计',
      icon: 'form',
      roles: isAmdinRs,
      keepAlive: true
    }
  }]
}, {
  path: '/user',
  component: Layout,
  meta: {
    title: '用户管理',
    icon: 'form',
    roles: isUserRs
  },
  redirect: '/user/list',
  children: [{
    path: 'list',
    name: 'user-list',
    component: function component() {
      return import('@/views/user/list');
    },
    meta: {
      title: '用户列表',
      icon: 'form',
      roles: isUserRs
    }
  }]
}, {
  path: '/balance',
  component: Layout,
  meta: {
    title: '小程序流量包查询',
    icon: 'form',
    roles: isUserRs
  },
  redirect: '/balance/list',
  children: [{
    path: 'list',
    name: 'user-list',
    component: function component() {
      return import('@/views/balance/balance');
    },
    meta: {
      title: '小程序流量包查询',
      icon: 'form',
      roles: isUserRs
    }
  }]
}, {
  path: '/disposition',
  component: Layout,
  meta: {
    title: '公共参数配置',
    icon: 'form',
    roles: isUserRs
  },
  redirect: 'disposition',
  children: [{
    path: 'disposition',
    name: 'disposition',
    component: function component() {
      return import('@/views/disposition/disposition');
    },
    meta: {
      title: '公共参数配置',
      icon: 'form',
      roles: isUserRs
    }
  }]
}, {
  path: '/feedback',
  component: Layout,
  meta: {
    title: '投诉反馈',
    icon: 'form',
    roles: isUserRs
  },
  redirect: '/feedback/index',
  children: [{
    path: 'index',
    name: 'feedback-index',
    component: function component() {
      return import('@/views/feedback/index');
    },
    meta: {
      title: '投诉反馈',
      icon: 'form',
      roles: isUserRs
    }
  }, {
    path: 'list',
    name: 'feedback-list',
    component: function component() {
      return import('@/views/feedback/list');
    },
    meta: {
      title: '错误日志',
      icon: 'form',
      roles: isUserRs
    }
  }, {
    path: 'errorRecord',
    name: 'feedback-errorRecord',
    component: function component() {
      return import('@/views/feedback/errorRecord');
    },
    meta: {
      title: '支付错误记录',
      icon: 'form',
      roles: isUserRs
    }
  }]
}, {
  path: '/shortplay',
  component: Layout,
  meta: {
    title: '短剧管理',
    icon: 'form',
    roles: isAmdinRs
  },
  redirect: '/shortplay/list',
  children: [{
    path: 'list',
    name: 'shortplay-list',
    component: function component() {
      return import('@/views/shortplay/list');
    },
    meta: {
      title: '短剧列表',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'information',
    name: 'shortplay-information',
    component: function component() {
      return import('@/views/shortplay/information');
    },
    meta: {
      title: '短剧资讯',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'urlFlushConfig',
    name: 'shortplay-urlFlushConfig',
    component: function component() {
      return import('@/views/shortplay/urlFlushConfig');
    },
    meta: {
      title: '剧目上下架',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'story',
    name: 'shortplay-story',
    component: function component() {
      return import('@/views/shortplay/story');
    },
    meta: {
      title: '故事投稿',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'stills',
    name: 'shortplay-stills',
    component: function component() {
      return import('@/views/shortplay/stills');
    },
    meta: {
      title: '精美剧照',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'swiper',
    name: 'shortplay-swiper',
    component: function component() {
      return import('@/views/shortplay/swiper');
    },
    meta: {
      title: '轮播图管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'hot',
    name: 'shortplay-hot',
    component: function component() {
      return import('@/views/shortplay/hot');
    },
    meta: {
      title: '最火一部',
      icon: 'form',
      roles: isAmdinRs
    }
  },
  // {
  //     path: 'hotSearch',
  //     name: 'hotSearch',
  //     component: () => import('@/views/shortplay/hotSearch'),
  //     meta: { title: '热门搜索配置', icon: 'form', roles: isAmdinRs  }
  // },
  {
    path: 'column',
    name: 'shortplay-column',
    component: function component() {
      return import('@/views/shortplay/column');
    },
    meta: {
      title: '推荐栏管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'category',
    name: 'shortplay-category',
    component: function component() {
      return import('@/views/shortplay/category');
    },
    meta: {
      title: '分类管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }]
}, {
  path: '/meal',
  component: Layout,
  meta: {
    title: '充值管理',
    icon: 'form',
    roles: isAmdinRs
  },
  redirect: '/meal/payment',
  children: [{
    path: 'payment',
    name: 'meal-payment',
    component: function component() {
      return import('@/views/meal/payment');
    },
    meta: {
      title: '支付管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'commodity',
    name: 'meal-commodity',
    component: function component() {
      return import('@/views/meal/commodity');
    },
    meta: {
      title: '商品列表',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'rechargeTemp',
    name: 'meal-rechargeTemp',
    component: function component() {
      return import('@/views/meal/rechargeTemp');
    },
    meta: {
      title: '自定义充值模板',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'buyMember',
    name: 'meal-buyMember',
    component: function component() {
      return import('@/views/meal/buyMember');
    },
    meta: {
      title: '会员购买',
      icon: 'form',
      roles: isAmdinRs
    }
  }]
}, {
  path: '/order',
  component: Layout,
  meta: {
    title: '订单管理',
    icon: 'form',
    roles: isUserRs
  },
  redirect: '/order/rechargeList',
  children: [{
    path: 'rechargeList',
    name: 'order-rechargeList',
    component: function component() {
      return import('@/views/order/rechargeList');
    },
    meta: {
      title: '充值记录',
      icon: 'form',
      roles: isUserRs
    }
  }
  // {
  // 	path: 'consumeList',
  // 	name: 'order-consumeList',
  // 	component: () => import('@/views/order/consumeList'),
  // 	meta: {
  // 		title: '消费记录',
  // 		icon: 'form',
  // 		roles: isUserRs
  // 	}
  // }
  ]
}, {
  path: '/flow_helper',
  component: Layout,
  meta: {
    title: '买量助手',
    icon: 'form',
    roles: isAmdinRs
  },
  redirect: '/flow_helper/monitoring',
  children: [{
    path: 'monitoring',
    name: 'flow_helper-monitoring',
    component: function component() {
      return import('@/views/flow_helper/monitoring');
    },
    meta: {
      title: '渠道监控',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'channelManage',
    name: 'flow_helper-channelManage',
    component: function component() {
      return import('@/views/flow_helper/channelManage');
    },
    meta: {
      title: '渠道管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'channelProvider',
    name: 'flow_helper-channelProvider',
    component: function component() {
      return import('@/views/flow_helper/channelProvider');
    },
    meta: {
      title: '渠道媒体管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'echoConfig',
    name: 'flow_helper-echoConfig',
    component: function component() {
      return import('@/views/flow_helper/echoConfig');
    },
    meta: {
      title: '回传配置',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'tencentAd',
    name: 'shortplay-tencentAd',
    component: function component() {
      return import('@/views/shortplay/tencentAd');
    },
    meta: {
      title: '广告管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }]
}, {
  path: '/statistics',
  component: Layout,
  meta: {
    title: '数据统计',
    icon: 'form',
    roles: isAmdinRs
  },
  redirect: '/statistics/userStatistics',
  children: [{
    path: 'userStatistics',
    name: 'statistics-userStatistics',
    component: function component() {
      return import('@/views/statistics/userStatistics');
    },
    meta: {
      title: '用户统计',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'orderStatistics',
    name: 'statistics-orderStatistics',
    component: function component() {
      return import('@/views/statistics/orderStatistics');
    },
    meta: {
      title: '订单统计',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'shortplayStatistics',
    name: 'statistics-shortplayStatistics',
    component: function component() {
      return import('@/views/statistics/shortplayStatistics');
    },
    meta: {
      title: '短剧统计',
      icon: 'form',
      roles: isAmdinRs
    }
  }]
}, {
  path: '/fsStatistics',
  component: Layout,
  meta: {
    title: '数据盯盘',
    icon: 'form',
    roles: isAmdinRs
  },
  redirect: '/fsStatistics/fsAccountData',
  children: [{
    path: 'faTotalDiskData',
    name: 'fsStatistics-faTotalDiskData',
    component: function component() {
      return import('@/views/fsStatistics/faTotalDiskData');
    },
    meta: {
      title: '盯总盘',
      icon: 'form',
      roles: isUserRs
    }
  }, {
    path: 'fsChannelData',
    name: 'fsStatistics-fsChannelData',
    component: function component() {
      return import('@/views/fsStatistics/fsChannelData');
    },
    meta: {
      title: '回收趋势',
      icon: 'form',
      roles: isUserRs
    }
  }, {
    path: 'fsPitcherData',
    name: 'fsStatistics-fsPitcherData',
    component: function component() {
      return import('@/views/fsStatistics/fsPitcherData');
    },
    meta: {
      title: '每日统计',
      icon: 'form',
      roles: isUserRs
    }
  }, {
    path: 'fsVideoData',
    name: 'fsStatistics-fsVideoData',
    component: function component() {
      return import('@/views/fsStatistics/fsVideoData');
    },
    meta: {
      title: '剧目统计',
      icon: 'form',
      roles: isUserRs
    }
  }, {
    path: 'fsAccountData',
    name: 'fsStatistics-fsAccountData',
    component: function component() {
      return import('@/views/fsStatistics/fsAccountData');
    },
    meta: {
      title: '账户分时',
      icon: 'form',
      roles: isUserRs
    }
  }, {
    path: 'fsAdvertiseData',
    name: 'fsStatistics-fsAdvertiseData',
    component: function component() {
      return import('@/views/fsStatistics/fsAdvertiseData');
    },
    meta: {
      title: '计划分时',
      icon: 'form',
      roles: isUserRs
    }
  }]
},
// {
// 	path: '/reportform',
// 	component: Layout,
// 	meta: {
// 		title: '数据报表',
// 		icon: 'form',
// 		roles: isAmdinRs
// 	},
// 	redirect: '/reportform/consumeRecord',
// 	children: [{
// 		path: 'consumeRecord',
// 		name: 'reportform-consumeRecord',
// 		component: () => import('@/views/reportform/consumeRecord'),
// 		meta: {
// 			title: '数据报表',
// 			icon: 'form',
// 			roles: isAmdinRs
// 		}
// 	}, ]
// },
{
  path: '/power',
  component: Layout,
  meta: {
    title: '账号管理',
    icon: 'form',
    roles: isAmdinRs
  },
  redirect: '/power/channel',
  children: [{
    path: 'channel',
    name: 'power-channel',
    component: function component() {
      return import('@/views/power/channel');
    },
    meta: {
      title: '渠道商账号',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'distributor',
    name: 'power-distributor',
    component: function component() {
      return import('@/views/power/distributor');
    },
    meta: {
      title: '小程序剧目权限',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'distributor_admin',
    name: 'power-distributor_admin',
    component: function component() {
      return import('@/views/power/distributor_admin');
    },
    meta: {
      title: '分销商管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'applets',
    name: 'power-applets',
    component: function component() {
      return import('@/views/power/applets');
    },
    meta: {
      title: '小程序管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }]
}, {
  path: '/wechat',
  component: Layout,
  meta: {
    title: '公众号设置',
    icon: 'form',
    roles: isAmdinRs
  },
  redirect: '/wechat/menu',
  children: [{
    path: 'authorization',
    name: 'wechat-authorization',
    component: function component() {
      return import('@/views/wechat/authorization');
    },
    meta: {
      title: '公众号关注',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'menu',
    name: 'wechat-menu',
    component: function component() {
      return import('@/views/wechat/menu');
    },
    meta: {
      title: '菜单管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'mindpush',
    name: 'wechat-mindpush',
    component: function component() {
      return import('@/views/wechat/mindpush');
    },
    meta: {
      title: '智能推送',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'autoreply',
    name: 'wechat-autoreply',
    component: function component() {
      return import('@/views/wechat/autoreply');
    },
    meta: {
      title: '关键字回复',
      icon: 'form',
      roles: isAmdinRs
    }
  }]
}, {
  path: '/welfare',
  component: Layout,
  meta: {
    title: '福利中心',
    icon: 'form',
    roles: isAmdinRs
  },
  redirect: '/welfare/task',
  children: [{
    path: 'task',
    name: 'welfare-task',
    component: function component() {
      return import('@/views/welfare/task');
    },
    meta: {
      title: '任务管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }, {
    path: 'sign',
    name: 'welfare-sign',
    component: function component() {
      return import('@/views/welfare/sign');
    },
    meta: {
      title: '签到管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }]
}, {
  path: '/problem',
  component: Layout,
  meta: {
    title: '',
    icon: 'form',
    roles: isAmdinRs
  },
  redirect: '/problem/list',
  children: [{
    path: 'list',
    name: 'problem-list',
    component: function component() {
      return import('@/views/problem/list');
    },
    meta: {
      title: '常见问题管理',
      icon: 'form',
      roles: isAmdinRs
    }
  }]
}, {
  path: '/system',
  component: Layout,
  meta: {
    title: '系统设置',
    icon: 'form',
    roles: isAmdinRs
  },
  redirect: '/system/quartz',
  children: [{
    path: 'quartz',
    name: 'system-quartz',
    component: function component() {
      return import('@/views/system/quartz');
    },
    meta: {
      title: '定时任务',
      icon: 'form',
      roles: isAmdinRs
    }
  }]
}];

// 解决重复点击导航路由报错
// 移除调试用的console.log
var originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    // 在这里记录错误，而不是直接返回
    console.error("Routing error:", err);
  });
};
var createRouter = function createRouter() {
  return new Router({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  // 在这里确保所有状态和数据都已经被正确地清理
  router.matcher = newRouter.matcher; // reset router
  console.log(router, "router.matcher");
}
export default router;